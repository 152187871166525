import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React from "react";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import {
  BooleanFacet,
  Layout,
  Result,
  SingleLinksFacet,
  SingleSelectFacet
} from "@elastic/react-search-ui-views";
//added//////////////////////////////////


 import type {SearchContextState, SearchResult} from "@elastic/search-ui";


/////////////////////////////////////////
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./dsu-style.css";
import { SearchDriverOptions } from "@elastic/search-ui";

const connector = new AppSearchAPIConnector({
  searchKey: "search-hjzwomoht1eigkacibr3jamz",
  engineName: "dsu-edu",
  endpointBase: "https://entsearch.dsu.edu:443"
});

export const config: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      title: { 
        snippet:{
          fallback: true
        } 
      },
      body: {
        snippet:{
          size: 250,
          fallback: true
        }
      },
      meta_description: {
        snippet:{
          size:250,
          fallback: true
        }
      },
      url: { raw:{} },
    },
    search_fields: {
      title: {},
      body: {},
      meta_description: {}
    },
    disjunctiveFacets: [""],
    facets: {}
  }
};