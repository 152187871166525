import React from "react";

import { config } from "./config";

import { SearchProvider, SearchBox } from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

export default function Header() {
  return (
    <div className="sui-search-bar-header">
      <div className="sui-search-bar-outside">
        <SearchProvider
          config={{
            ...config,
            trackUrlState: false
          }}
        >
          <SearchBox
            onSubmit={(searchTerm) => {
            window.location.href = `${window.location.origin}/search.html?q=${searchTerm}`
            }}
            inputProps={{placeholder: "What are you looking for?", required: true}}
            autocompleteSuggestions={true}
            debounceLength={0}
          />
        </SearchProvider>
      </div>
    </div>
  );
}
