import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React from "react";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import {
  BooleanFacet,
  Layout,
  Result,
  SingleLinksFacet,
  SingleSelectFacet
} from "@elastic/react-search-ui-views";
//added//////////////////////////////////


 import type {SearchContextState, SearchResult} from "@elastic/search-ui";
 import Header from "./Header";

/////////////////////////////////////////
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./dsu-style.css";
import { SearchDriverOptions } from "@elastic/search-ui";

export default function App() {
  return (
    <div>
      <Header />
    </div>
  )
}